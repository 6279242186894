import axios from "axios";
const instance = axios.create({
  // baseURL: "http://192.168.29.183:5007/api/",
  // baseURL: "http://192.168.0.218:4137/api/",
  // baseURL: "http://localhost:4137/api/",
  // baseURL: "https://api.rushividyabhyasam.org/api/",
  baseURL: "https://api.rushividyabhasam.spinoffedutech.com/api/",
  // baseURL: "http://65.1.225.132/api/",
  // http://183.83.219.220:4137/api/"
});
instance.defaults.headers.common["Content-Type"] = "application/json";
// instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
